/**
 * For multi-dimensional arrays.
 * Filters out eventual `undefined` values after zipping
 * if row lengths are not equal.
 *
 * Mostly taken from https://stackoverflow.com/questions/4856717/javascript-equivalent-of-pythons-zip-function
 *
 * @param {Array} rows
 */
export const zip = rows =>
  rows[0].map((_, c) => rows.map(row => row[c]).filter(x => x))
