import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import helmetProps from '../components/Pricing/helmetProps'
import Pricing from '../components/Pricing'

export const query = graphql`
  {
    individual: file(
      name: { eq: "Illustration_Individual" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 154, width: 191, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    enterprise: file(
      name: { eq: "Illustration_Enterprise" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 120, width: 237, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    workstation: file(
      name: { eq: "Illustration_Workstation" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 166, width: 229, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    workflow: file(
      name: { eq: "Workflow_Diagram" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 176, width: 1084, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    feature1: file(
      name: { eq: "features_1" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 60, width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    feature2: file(
      name: { eq: "features_2" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 60, width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    feature3: file(
      name: { eq: "features_3" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 60, width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    feature4: file(
      name: { eq: "features_4" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 60, width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    feature5: file(
      name: { eq: "features_5" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 60, width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    feature6: file(
      name: { eq: "features_6" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 60, width: 60, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    integration1: file(
      name: { eq: "i1" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 54, width: 55, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    integration2: file(
      name: { eq: "i2" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 53, width: 41, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    integration3: file(
      name: { eq: "i3" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 56, width: 70, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    integration4: file(
      name: { eq: "i4" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 51, width: 51, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    integration5: file(
      name: { eq: "i5" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 64, width: 64, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    support1: file(name: { eq: "s1" }, relativeDirectory: { eq: "pricing" }) {
      id
      name
      childImageSharp {
        fixed(height: 54, width: 89, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    support2: file(name: { eq: "s2" }, relativeDirectory: { eq: "pricing" }) {
      id
      name
      childImageSharp {
        fixed(height: 69, width: 68, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    support3: file(name: { eq: "s3" }, relativeDirectory: { eq: "pricing" }) {
      id
      name
      childImageSharp {
        fixed(height: 54, width: 75, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    support4: file(name: { eq: "s4" }, relativeDirectory: { eq: "pricing" }) {
      id
      name
      childImageSharp {
        fixed(height: 73, width: 59, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    support5: file(name: { eq: "s5" }, relativeDirectory: { eq: "pricing" }) {
      id
      name
      childImageSharp {
        fixed(height: 61, width: 61, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    prospectLogo: file(
      name: { eq: "prospect_logo" }
      relativeDirectory: { eq: "pricing" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 37, width: 210, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  return (
    <>
      <Helmet {...helmetProps} />
      <Pricing data={props.data} />
    </>
  )
})
