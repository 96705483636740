import React, { useState, useMemo, useEffect } from 'react'
import { Layout, IrisImg, IrisCTALinkButton, ModalWrapper } from '../Shared'
import { getDomain } from '../../utils/url'
import { chunk } from '../../utils/chunk'
import { zip } from '../../utils/zip'
import { Link } from 'gatsby'

const { GATSBY_IRIS_SIGNUP_URL } = process.env

/**
 * Calculated view is slightly different for this page
 * than other pages
 */
const calculatedView = width => {
  let view = 'desktop'
  if (width < 1100) view = 'tablet'
  if (width < 600) view = 'mobile'

  return view
}

const Header = ({ view }) => {
  const isDesktop = view === 'desktop'
  const isTablet = view === 'tablet'
  return (
    <div
      style={{
        paddingTop: isDesktop ? 112 : 74,
        paddingBottom: isDesktop ? 90 : 93,
        ...(isDesktop
          ? {}
          : {
              paddingLeft: 30,
              paddingRight: 30,
              letterSpacing: '0px',
              fontFamily: 'irisvr',
            }),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            fontSize: isDesktop ? 55 : 34,
            fontWeight: 'bold',
            color: '#000000',
            paddingBottom: 32,
          }}
        >
          Choose The Best Plan For You
        </div>
        <div
          style={{
            fontSize: isDesktop || isTablet ? 21 : 14,
            color: '#333333',
            maxWidth: 628,
          }}
        >
          Every Prospect license can host VR meetings, has unlimited file
          uploads, and unlimited storage.
        </div>
      </div>
    </div>
  )
}

const ContactModal = ({
  isOpen,
  handleClose,
  accountTypeKey,
  workstationImage,
  enterpriseImage,
  isDesktop,
}) => {
  const isWorkstation = accountTypeKey === ACCOUNT_TYPE_KEYS.WORKSTATION
  return (
    <ModalWrapper isOpen={isOpen} handleClose={handleClose}>
      <div
        style={{
          ...(isDesktop ? { width: 357 } : {}),
          height: isWorkstation ? 470 : 425,
        }}
      >
        <div
          style={{
            paddingTop: 13,
            paddingBottom: 52,
            letterSpacing: '0.02em',
            fontSize: 34,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          Contact Us
        </div>
        <div style={{ paddingBottom: 42 }}>
          <IrisImg
            className={`${accountTypeKey}-image`}
            file={isWorkstation ? workstationImage : enterpriseImage}
            alt={`${accountTypeKey}-image`}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 266,
              height: 127,
              fontSize: 18,
              textAlign: 'center',
              lineHeight: '30px',
            }}
          >
            Thank you for your interest. Please email{' '}
            <a
              href="mailto:sales@irisvr.com"
              style={{ fontWeight: 'bold', color: '#534DC9' }}
            >
              sales@irisvr.com
            </a>{' '}
            or call{' '}
            <span style={{ fontWeight: 'bold', color: '#534DC9' }}>
              +1 (646) 876-0335
            </span>{' '}
            for more information.
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

const ACCOUNT_TYPE_KEYS = {
  INDIVIDUAL: 'individual',
  WORKSTATION: 'workstation',
  ENTERPRISE: 'enterprise',
}

const tierCardData = {
  individual: {
    accountTypeKey: ACCOUNT_TYPE_KEYS.INDIVIDUAL,
    title: 'Individual',
    price: '225',
    description: 'For individuals with access to their own personal headset.',
    cta: 'Buy now',
    href: `https://${getDomain()}/checkout?plan=prospect-pro-plus&cycle=annual`,
  },
  workstation: {
    accountTypeKey: ACCOUNT_TYPE_KEYS.WORKSTATION,
    title: 'Workstation',
    price: '350',
    description: 'For teams using a shared VR headset.',
    cta: 'Contact us',
  },
  enterprise: {
    accountTypeKey: ACCOUNT_TYPE_KEYS.ENTERPRISE,
    title: 'Enterprise',
    price: 'Volume discounts + custom bundles available.',
    description: 'For firms looking to deploy VR at scale.',
    cta: 'Contact us',
  },
}

const TierCard = ({
  image,
  tierCardData,
  isDesktop,
  setIsModalOpen,
  setAccountTypeKey,
}) => {
  const { title, price, description, accountTypeKey, cta, href } = tierCardData
  const isEnterprise = accountTypeKey === ACCOUNT_TYPE_KEYS.ENTERPRISE
  const isIndividual = accountTypeKey === ACCOUNT_TYPE_KEYS.INDIVIDUAL
  const isWorkstation = accountTypeKey === ACCOUNT_TYPE_KEYS.WORKSTATION

  return (
    <div
      style={{
        width: 345,
        height: isDesktop ? 613 : 527,
        border: '1.08033px solid #96A7E8',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: isEnterprise ? '#534DC8' : 'white',
      }}
    >
      <div
        style={{
          paddingTop: 43,
          paddingLeft: 77,
          paddingRight: 77,
          paddingBottom: isDesktop ? 54 : isEnterprise ? 34 : 22,
        }}
      >
        <div
          style={{
            color: isEnterprise ? 'white' : 'black',
            fontSize: isDesktop ? 34 : 30,
            letterSpacing: '0.02em',
            fontWeight: 'bold',
          }}
        >
          {title}
        </div>
      </div>
      <div
        style={
          isEnterprise
            ? { paddingBottom: isDesktop ? 53 : 20, paddingTop: 28 }
            : isIndividual
            ? { paddingBottom: isDesktop ? 45 : 22 }
            : { paddingBottom: isDesktop ? 33 : 22 } // Workstation
        }
      >
        <IrisImg
          className={`${accountTypeKey}-image`}
          file={image}
          alt={`${accountTypeKey}-image`}
        />
      </div>
      {isEnterprise ? (
        <div style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              color: 'white',
              fontSize: 20,
              textAlign: 'center',
              width: 250,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {price}
          </div>
        </div>
      ) : (
        <div style={{ marginBottom: isDesktop ? 29 : 11 }}>
          <div
            style={{
              paddingLeft: 77,
              paddingRight: 77,
              display: 'flex',
              color: '#534DC9',
            }}
          >
            <div style={{ fontSize: 26, paddingTop: 7, paddingRight: 6 }}>
              $
            </div>
            <span style={{ fontSize: 43, fontWeight: 'bold' }}>
              {price}
              <span style={{ fontWeight: 'normal' }}>/mo</span>
            </span>
          </div>
        </div>
      )}
      <div
        style={{
          fontSize: isDesktop ? 16 : 17,
          textAlign: 'center',
          width:
            isEnterprise && !isDesktop
              ? 196
              : isWorkstation && !isDesktop
              ? 192
              : 233,
          paddingBottom: 30,
          color: isEnterprise ? 'white' : '#333333',
        }}
      >
        {description}
      </div>
      <div style={{ paddingBottom: 63 }}>
        {useMemo(() => {
          /**
           * Without memoization, button classes are recalculated
           * for some reason when closing the modal
           */
          if (isIndividual) {
            return <IrisCTALinkButton cta={cta} theme={'inverse'} href={href} />
          }

          return (
            <IrisCTALinkButton
              cta={cta}
              theme={'inverse'}
              onClick={() => {
                setIsModalOpen(true)
                setAccountTypeKey(accountTypeKey)
              }}
            />
          )
        }, [isDesktop])}
      </div>
    </div>
  )
}

const BilledAnnuallyText = ({ isDesktop }) => {
  return (
    <div
      style={{
        color: '#837C7C',
        textAlign: 'center',
        fontSize: isDesktop ? 16 : 12,
      }}
    >
      Billed Annually
    </div>
  )
}

const TierCards = ({ imageData, isDesktop }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [accountTypeKey, setAccountTypeKey] = useState(
    ACCOUNT_TYPE_KEYS.ENTERPRISE
  )

  /**
   * Desktop version
   */
  if (isDesktop) {
    return (
      <div style={{ paddingBottom: 108 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 25,
          }}
        >
          <div style={{ paddingRight: 33 }}>
            <div style={{ paddingTop: 45 }}>
              <TierCard
                image={imageData.individual}
                tierCardData={tierCardData.individual}
                isDesktop={isDesktop}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setAccountTypeKey={setAccountTypeKey}
              />
            </div>
          </div>
          <div style={{ paddingRight: 33 }}>
            <div
              style={{
                width: 345,
                height: 44,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#F2ECFF',
                color: '#534DC8',
                textTransform: 'uppercase',
                letterSpacing: '0.02em',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 16,
              }}
            >
              Recommended for teams!
            </div>
            <TierCard
              image={imageData.enterprise}
              tierCardData={tierCardData.enterprise}
              isDesktop={isDesktop}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setAccountTypeKey={setAccountTypeKey}
            />
          </div>
          <div style={{ paddingTop: 45 }}>
            <TierCard
              image={imageData.workstation}
              tierCardData={tierCardData.workstation}
              isDesktop={isDesktop}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setAccountTypeKey={setAccountTypeKey}
            />
          </div>
        </div>
        <BilledAnnuallyText isDesktop={isDesktop} />
        <ContactModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          accountTypeKey={accountTypeKey}
          workstationImage={imageData.workstation}
          enterpriseImage={imageData.enterprise}
          isDesktop={isDesktop}
        />
      </div>
    )
  }

  /**
   * Mobile and Tablet version
   * Most notably these cards are re-ordered from Desktop
   */
  return (
    <div style={{ paddingBottom: 65 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: 345,
            height: 44,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F2ECFF',
            color: '#534DC8',
            textTransform: 'uppercase',
            letterSpacing: '0.02em',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          Recommended for teams!
        </div>
        <div style={{ paddingBottom: 19 }}>
          <TierCard
            image={imageData.enterprise}
            tierCardData={tierCardData.enterprise}
            isDesktop={isDesktop}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setAccountTypeKey={setAccountTypeKey}
          />
        </div>
        <div style={{ paddingBottom: 19 }}>
          <TierCard
            image={imageData.individual}
            tierCardData={tierCardData.individual}
            isDesktop={isDesktop}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setAccountTypeKey={setAccountTypeKey}
          />
        </div>
        <TierCard
          image={imageData.workstation}
          tierCardData={tierCardData.workstation}
          isDesktop={isDesktop}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setAccountTypeKey={setAccountTypeKey}
        />
      </div>
      <div style={{ paddingTop: 12 }}>
        <BilledAnnuallyText isDesktop={isDesktop} />
      </div>
      <ContactModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        accountTypeKey={accountTypeKey}
        workstationImage={imageData.workstation}
        enterpriseImage={imageData.enterprise}
        isDesktop={isDesktop}
      />
    </div>
  )
}

const BulletPointCircle = () => {
  return (
    <div
      style={{
        display: 'inline-block',
        marginRight: 15,
        backgroundColor: '#534DC9',
        borderRadius: '50%',
        width: 10,
        height: 10,
      }}
    />
  )
}

const VRWorkflows = ({ image, view }) => {
  const isDesktop = view === 'desktop'
  const isTablet = view === 'tablet'
  const copy = 'Key AEC Workflows That Deliver Projects On Time And On Budget'
  if (isDesktop) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 160,
        }}
      >
        <div style={{ paddingBottom: 107 }}>
          <div
            style={{
              maxWidth: 548,
              height: 83,
              fontSize: 34,
              textAlign: 'center',
              color: 'black',
            }}
          >
            {copy}
          </div>
        </div>
        <IrisImg
          className={`workflows-image`}
          file={image}
          alt={`workflows-image`}
        />
      </div>
    )
  }

  if (isTablet) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 98,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ paddingBottom: 40 }}>
          <div
            style={{
              maxWidth: 548,
              fontSize: 28,
              textAlign: 'center',
              color: 'black',
              paddingLeft: 44,
              paddingRight: 44,
              fontWeight: 'bold',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {copy}
          </div>
        </div>
        <div
          style={{
            textTransform: 'uppercase',
            color: '#534DC9',
            fontSize: 16,
            fontWeight: 'bold',
            marginLeft: -100,
          }}
        >
          {[
            'VR Meetings',
            'VR Client Presentations',
            'VR Design Reviews',
            'VR Coordination',
            'VR Training',
          ].map((copy, key) => {
            const isLast = key === 4
            return (
              <div
                key={key}
                style={{ lineHeight: '26px', paddingBottom: isLast ? 0 : 15 }}
              >
                <BulletPointCircle />
                <span>{copy}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  /**
   * Mobile
   */
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 98,
      }}
    >
      <div style={{ paddingBottom: 40 }}>
        <div
          style={{
            maxWidth: 548,
            fontSize: 28,
            textAlign: 'center',
            color: 'black',
            paddingLeft: 30,
            paddingRight: 30,
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {copy}
        </div>
      </div>
      <div
        style={{
          textTransform: 'uppercase',
          color: '#534DC9',
          fontSize: 16,
          fontWeight: 'bold',
          marginLeft: 63,
        }}
      >
        {[
          'VR Meetings',
          'VR Client Presentations',
          'VR Design Reviews',
          'VR Coordination',
          'VR Training',
        ].map((copy, key) => {
          const isLast = key === 4
          return (
            <div
              key={key}
              style={{ lineHeight: '26px', paddingBottom: isLast ? 0 : 15 }}
            >
              <BulletPointCircle />
              <span>{copy}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const featureCopyAry = [
  <div>
    Unlimited
    <br />
    Projects
  </div>,
  <div>
    Inspect BIM
    <br />
    Properties
  </div>,
  <div>
    No Headset
    <br />
    Mode
  </div>,
  <div>
    Export
    <br />
    Reports
  </div>,
  <div>
    {'Annotations &'}
    <br />
    Measurements
  </div>,
  <div>
    Host
    <br />
    Controls
  </div>,
]

const Features = ({ images, copyAry, isDesktop }) => {
  return (
    <div style={{ backgroundColor: '#534DC9' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: isDesktop ? 88 : 51,
          paddingBottom: isDesktop ? 88 : 51,
          fontSize: 34,
          color: 'white',
        }}
      >
        Features
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: isDesktop ? 168 : 70,
        }}
      >
        {!isDesktop && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {zip(chunk(zip([images, copyAry]), 2)).map(
              (featureDataColumn, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    {featureDataColumn.map(([image, copy], idx) => {
                      const isBottomRowItem = idx === 2
                      return (
                        <div
                          key={idx}
                          style={{ paddingBottom: isBottomRowItem ? 0 : 40 }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <IrisImg
                              className={`feature-image`}
                              file={image}
                              alt={`feature-image`}
                            />
                          </div>
                          <div
                            style={{
                              color: 'white',
                              fontSize: 16,
                              textAlign: 'center',
                            }}
                          >
                            {copy}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              }
            )}
          </div>
        )}
        {isDesktop &&
          images.map((image, key) => {
            const isLast = key === 5
            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ paddingRight: isLast ? 0 : 77 }}>
                  <div
                    style={{
                      paddingBottom: 37,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <IrisImg
                      className={`feature-image`}
                      file={image}
                      alt={`feature-image`}
                    />
                  </div>
                  <div
                    style={{
                      color: 'white',
                      fontSize: 16,
                      textAlign: 'center',
                    }}
                  >
                    {featureCopyAry[key]}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const AccentBorder = ({ isDesktop }) => {
  return (
    <div
      style={{
        width: isDesktop ? 75 : 54,
        border: `${isDesktop ? '5px' : '3px'} solid #534DC9`,
      }}
    />
  )
}

const integrationsCopyAry = [
  <div>Navisworks</div>,
  <div>Revit</div>,
  <div>Rhino</div>,
  <div>SketchUp</div>,
  <div>
    OBJ, FBX,
    <br />
    and more
  </div>,
]

const integrationsHyperlinksAry = [
  '/integrations/navisworks',
  '/integrations/revit',
  '/integrations/rhino',
  '/integrations/sketchup',
  '', // OBJ, FBX will not link anywhere
]

const LinkWrapper = ({ children, to }) => {
  if (Boolean(to)) {
    return (
      <Link to={to} style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    )
  }

  return <div>{children}</div>
}

const Integrations = ({ images, copyAry, isDesktop }) => {
  return (
    <div
      style={{
        paddingTop: isDesktop ? 110 : 46,
        paddingBottom: isDesktop ? 96 : 58,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <AccentBorder isDesktop={isDesktop} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...(isDesktop ? {} : { flexDirection: 'column' }),
            }}
          >
            <div
              style={{
                paddingRight: isDesktop ? 120 : 0,
                marginTop: isDesktop ? -16 : 10,
              }}
            >
              <div
                style={{
                  fontSize: isDesktop ? 34 : 21,
                  width: 332,
                  height: 103,
                  lineHeight: isDesktop ? '50px' : '37px',
                }}
              >
                Integrations with the
                <br /> Leading 3D Tools
              </div>
            </div>
            {!isDesktop && (
              <div style={{ display: 'flex' }}>
                {zip(
                  chunk(zip([images, copyAry, integrationsHyperlinksAry]), 3)
                ).map((imageCopyColumn, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                      }}
                    >
                      {imageCopyColumn.map(([image, copy, link], idx) => {
                        const isTopRowItem = idx === 0
                        return (
                          <div
                            key={idx}
                            style={{
                              paddingRight: 32,
                              paddingBottom: isTopRowItem ? 52 : 0,
                            }}
                          >
                            <LinkWrapper to={link}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ paddingBottom: 25 }}>
                                  <IrisImg
                                    className={`integrations-image`}
                                    file={image}
                                    alt={`integrations-image`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  color: 'black',
                                  fontSize: 16,
                                  textAlign: 'center',
                                }}
                              >
                                {copy}
                              </div>
                            </LinkWrapper>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            )}
            {isDesktop &&
              images.map((image, key) => {
                const isLast = key === 4
                const isOBJAndFBXPlus = isLast
                return (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ paddingRight: isLast ? 0 : 56 }}>
                      <LinkWrapper to={integrationsHyperlinksAry[key]}>
                        <div
                          style={{
                            paddingBottom: isOBJAndFBXPlus ? 31 : 36,
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: isOBJAndFBXPlus ? 14 : 0,
                          }}
                        >
                          <IrisImg
                            className={`integrations-image`}
                            file={image}
                            alt={`integrations-image`}
                          />
                        </div>
                        <div
                          style={{
                            color: 'black',
                            fontSize: 16,
                            textAlign: 'center',
                          }}
                        >
                          {integrationsCopyAry[key]}
                        </div>
                      </LinkWrapper>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const vrSupportCopyAry = [
  <div>
    Oculus Rift S<br />
    Meta Quest 2
  </div>,
  <div>HTC Vive</div>,
  <div>
    Valve
    <br />
    Index
  </div>,
  <div>
    Windows
    <br />
    MR VR
  </div>,
  <div>
    And
    <br />
    More!
  </div>,
]

const SupportedVRHeadsetLinkWrapper = ({ children }) => {
  return (
    <Link to={'/supported-headsets'} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  )
}

const VRSupport = ({ images, copyAry, isDesktop }) => {
  return (
    <div
      style={{
        paddingTop: isDesktop ? 110 : 46,
        paddingBottom: isDesktop ? 96 : 58,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <div style={{ paddingBottom: isDesktop ? 18 : 0 }}>
            <AccentBorder isDesktop={isDesktop} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...(isDesktop ? {} : { flexDirection: 'column' }),
            }}
          >
            <div
              style={{
                paddingRight: isDesktop ? 120 : 0,
                marginTop: isDesktop ? -16 : 10,
              }}
            >
              <div
                style={{
                  fontSize: isDesktop ? 34 : 21,
                  width: 332,
                  height: 103,
                  lineHeight: isDesktop ? '50px' : '37px',
                }}
              >
                Support for the
                <br /> Top VR Headsets
              </div>
            </div>
            {!isDesktop && (
              <div style={{ display: 'flex' }}>
                {zip(chunk(zip([images, copyAry]), 3)).map(
                  (imageCopyColumn, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {imageCopyColumn.map(([image, copy], idx) => {
                          const isTopRowItem = idx === 0
                          return (
                            <div
                              key={idx}
                              style={{
                                paddingRight: 32,
                                paddingBottom: isTopRowItem ? 52 : 0,
                              }}
                            >
                              <SupportedVRHeadsetLinkWrapper>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{ paddingBottom: 25 }}>
                                    <IrisImg
                                      className={`vr-support-image`}
                                      file={image}
                                      alt={`vr-support-image`}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: 'black',
                                    fontSize: 16,
                                    textAlign: 'center',
                                  }}
                                >
                                  {copy}
                                </div>
                              </SupportedVRHeadsetLinkWrapper>
                            </div>
                          )
                        })}
                      </div>
                    )
                  }
                )}
              </div>
            )}
            {isDesktop &&
              images.map((image, key) => {
                const isLast = key === 4
                const isOculus = key === 0
                const isVive = key === 1
                const isValve = key === 2
                const isWindows = key === 3
                const isMoreVRHeadsets = key === 4
                return (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ paddingRight: isLast ? 0 : 56 }}>
                      <SupportedVRHeadsetLinkWrapper>
                        <div
                          style={{
                            paddingBottom: 28,
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: isOculus
                              ? -7
                              : isVive
                              ? -32
                              : isWindows
                              ? -13
                              : isMoreVRHeadsets
                              ? -6
                              : 0,
                          }}
                        >
                          <IrisImg
                            className={`vr-support-image`}
                            file={image}
                            alt={`vr-support-image`}
                          />
                        </div>
                        <div
                          style={{
                            color: 'black',
                            fontSize: 16,
                            textAlign: 'center',
                            marginTop: isVive
                              ? -10
                              : isValve
                              ? -4
                              : isWindows
                              ? -11
                              : isMoreVRHeadsets
                              ? -6
                              : 0,
                          }}
                        >
                          {vrSupportCopyAry[key]}
                        </div>
                      </SupportedVRHeadsetLinkWrapper>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const QuestionsContactUs = ({ logoImage, contactModalImage, isDesktop }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div
      style={{
        backgroundColor: 'black',
        paddingTop: isDesktop ? 105 : 77,
        paddingBottom: isDesktop ? 118 : 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ paddingBottom: isDesktop ? 52 : 35 }}>
        <IrisImg className={`logo-image`} file={logoImage} alt={`logo-image`} />
      </div>
      <div style={{ paddingBottom: isDesktop ? 56 : 35 }}>
        <div
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: isDesktop ? 42 : 27,
            letterSpacing: '0.40625px',
            textAlign: 'center',
            ...(isDesktop ? { paddingLeft: 57, paddingRight: 57 } : {}),
          }}
        >
          Questions about our pricing plans?
        </div>
      </div>
      {useMemo(() => {
        /**
         * Without memoization, button classes are recalculated
         * for some reason when closing the modal
         */
        return (
          <IrisCTALinkButton
            cta={'Contact Us'}
            theme={'blackOnBlack'}
            onClick={() => setIsModalOpen(true)}
          />
        )
      }, [isDesktop])}
      <ContactModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        accountTypeKey={ACCOUNT_TYPE_KEYS.ENTERPRISE}
        enterpriseImage={contactModalImage}
        workstationImage={null}
        isDesktop={isDesktop}
      />
    </div>
  )
}

const IntegrationSupportDivider = ({ isDesktop }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: isDesktop ? 1102 : 317,
          border: '0.5px solid #727272',
        }}
      />
    </div>
  )
}

const Question = ({ copy }) => {
  return (
    <div style={{ fontWeight: 'bold', color: 'black', paddingBottom: 25 }}>
      {copy}
    </div>
  )
}

const AnswerWrapper = ({ children, isDesktop }) => {
  return (
    <div style={{ color: '#333333', paddingBottom: isDesktop ? 75 : 45 }}>
      {children}
    </div>
  )
}

const Answer = ({ copy, isDesktop }) => {
  return <AnswerWrapper isDesktop={isDesktop}>{copy}</AnswerWrapper>
}

const AnswerLink = ({ copy, href, target, rel }) => {
  return (
    <span
      style={{
        color: '#534DC9',
        textDecoration: 'underline',
        fontWeight: 'bold',
      }}
    >
      <a
        href={href}
        {...(target ? (target = { target }) : {})}
        {...(rel ? (rel = { rel }) : {})}
      >
        {copy}
      </a>
    </span>
  )
}

const QAColumn = ({ children, isDesktop }) => {
  return (
    <div
      style={{
        width: isDesktop ? 430 : 289,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  )
}

const questionsCopy = [
  'Is the trial really free?',
  'How does the 14-day trial work?',
  'My trial is about to end - how do I continue using the software?',
  'How am I billed for my subscription?',
  'Do you offer discounts on multiple license purchases?',
]

const [
  QuestionOne,
  QuestionTwo,
  QuestionThree,
  QuestionFour,
  QuestionFive,
] = questionsCopy.map(copy => <Question copy={copy} />)

const AnswerOne = ({ isDesktop }) => (
  <Answer
    copy={'Yes. A credit card is not required to start a trial.'}
    isDesktop={isDesktop}
  />
)

const AnswerTwo = ({ isDesktop }) => {
  return (
    <AnswerWrapper isDesktop={isDesktop}>
      Click Start Trial for Prospect on{' '}
      <AnswerLink copy={'this page'} href={`${GATSBY_IRIS_SIGNUP_URL}`} />, fill
      in the form, and then Start Trial. That's it! During your trial, you'll
      have access to the full feature set and you can use the software as often
      as you like.
    </AnswerWrapper>
  )
}

const AnswerThree = ({ isDesktop }) => (
  <Answer
    isDesktop={isDesktop}
    copy={
      'At any point during your free trial, you will have the ability to subscribe to an annual or monthly account of Prospect. To subscribe, simply log into your account and enter your billing information. We support invoicing for team and bulk license purchases.'
    }
  />
)

const AnswerFour = ({ isDesktop }) => {
  return (
    <AnswerWrapper isDesktop={isDesktop}>
      Subscriptions automatically renew at the end of the billing term. If you
      need to cancel your subscription, you must notify is prior to your next
      billing cycle by{' '}
      <AnswerLink
        copy={'submitting a message'}
        href={'https://help.irisvr.com/hc/en-us/requests/new'}
      />
      . Please note that we do not provide refunds for paid subscriptions that
      are cancelled mid-billing term.
    </AnswerWrapper>
  )
}

const AnswerFive = ({ isDesktop }) => {
  return (
    <AnswerWrapper isDesktop={isDesktop}>
      Yes. Please send us a message to{' '}
      <AnswerLink copy={'sales@irisvr.com'} href={'mailto:sales@irisvr.com'} />{' '}
      with the subscription tier, quantity you are interested in, and your
      contact details. Our team will then reply back to you with more
      information.
    </AnswerWrapper>
  )
}

const FAQ = ({ isDesktop }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          paddingTop: isDesktop ? 63 : 43,
          paddingBottom: isDesktop ? 78 : 51,
        }}
      >
        <div
          style={{
            color: 'black',
            fontSize: 32,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          FAQ
        </div>
      </div>
      {!isDesktop && (
        <div style={{ paddingBottom: 75 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontSize: 14,
            }}
          >
            <QAColumn isDesktop={isDesktop}>
              {QuestionOne}
              <AnswerOne isDesktop={isDesktop} />
              {QuestionTwo}
              <AnswerTwo isDesktop={isDesktop} />
              {QuestionThree}
              <AnswerThree isDesktop={isDesktop} />
              {QuestionFour}
              <AnswerFour isDesktop={isDesktop} />
              {QuestionFive}
              <AnswerFive isDesktop={isDesktop} />
              <div style={{ color: '#333333' }}>
                For more account and billing related questions please visit our{' '}
                <AnswerLink
                  copy={'Knowledge Base'}
                  href="https://help.irisvr.com"
                  target="_blank"
                  rel="noopener noreferrer"
                />
                .
              </div>
            </QAColumn>
          </div>
        </div>
      )}
      {isDesktop && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: 19,
          }}
        >
          <div
            style={{ paddingRight: 155, marginLeft: 15, paddingBottom: 100 }}
          >
            <QAColumn>
              {QuestionOne}
              <AnswerOne />
              {QuestionTwo}
              <AnswerTwo />
              {QuestionThree}
              <AnswerThree />
            </QAColumn>
          </div>
          <QAColumn>
            {QuestionFour}
            <AnswerFour />
            {QuestionFive}
            <AnswerFive />
            <div style={{ color: '#333333' }}>
              For more account and billing related questions please visit our{' '}
              <AnswerLink
                copy={'Knowledge Base'}
                href="https://help.irisvr.com"
                target="_blank"
                rel="noopener noreferrer"
              />
              .
            </div>
          </QAColumn>
        </div>
      )}
    </div>
  )
}

const Pricing = props => {
  const {
    width,
    data: {
      // data is all images
      individual,
      enterprise,
      workstation,
      workflow,
      feature1,
      feature2,
      feature3,
      feature4,
      feature5,
      feature6,
      integration1,
      integration2,
      integration3,
      integration4,
      integration5,
      support1,
      support2,
      support3,
      support4,
      support5,
      prospectLogo,
    },
  } = props
  const [view, setView] = useState('desktop')

  useEffect(() => {
    setView(calculatedView(width))
  }, [width])

  const isDesktop = view === 'desktop'

  return (
    <div>
      <Header view={view} />
      <TierCards
        imageData={{ individual, enterprise, workstation }}
        isDesktop={isDesktop}
      />
      <VRWorkflows image={workflow} view={view} />
      <Features
        images={[feature1, feature2, feature3, feature4, feature5, feature6]}
        copyAry={featureCopyAry}
        isDesktop={isDesktop}
      />
      <Integrations
        images={[
          integration1,
          integration2,
          integration3,
          integration4,
          integration5,
        ]}
        copyAry={integrationsCopyAry}
        isDesktop={isDesktop}
      />
      <IntegrationSupportDivider isDesktop={isDesktop} />
      <VRSupport
        images={[support1, support2, support3, support4, support5]}
        copyAry={vrSupportCopyAry}
        isDesktop={isDesktop}
      />
      <QuestionsContactUs
        isDesktop={isDesktop}
        logoImage={prospectLogo}
        contactModalImage={enterprise}
      />
      <FAQ isDesktop={isDesktop} />
    </div>
  )
}

export default props => {
  return (
    <Layout>
      <Pricing id="pricing-page" {...props} />
    </Layout>
  )
}
