/**
 * Creates a new array of arrays of the specified
 * chunk size
 * @param {Array} ary
 * @param {number} chunksize
 */
export const chunk = (ary, chunksize) => {
  let results = []
  let dupAry = ary.slice()

  while (dupAry.length) {
    results.push(dupAry.splice(0, chunksize))
  }

  return results
}
