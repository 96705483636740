export const generateMetaTags = ({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
}) => {
  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'google-site-verification',
      content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION_API_KEY || '',
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: image,
    },
    {
      property: 'og:image:width',
      content: `${imageWidth}`,
    },
    {
      property: 'og:image:height',
      content: `${imageHeight}`,
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'twitter:card',
      content: 'summary',
    },
    {
      property: 'twitter:site',
      content: '@IrisVR_Inc',
    },
    {
      property: 'twitter:title',
      content: title,
    },
    {
      property: 'twitter:description',
      content: description,
    },
    {
      property: 'twitter:image',
      content: image,
    },
    {
      property: 'twitter:image:alt',
      content: description,
    },
    {
      property: 'pinterest',
      content: 'nopin',
    },
  ]

  // Favicon
  const link = [
    {
      rel: 'shortcut icon',
      type: 'image/png',
      href: 'https://assets.irisvr.com/assets/static/iris-icon-black.png',
    },
  ]

  if (process.env.NODE_ENV !== 'production') {
    meta.push({
      property: 'robots',
      content: 'noindex',
    })
  }

  // scripts
  const script = []

  return {
    script,
    title,
    meta,
    link,
  }
}
